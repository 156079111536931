import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import ChatPage from "./components/ChatPage";
import socketIO from "socket.io-client";
import { paths } from "./routes/index";

const socket = socketIO.connect(process.env.REACT_APP_API_URL);

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route
            path={paths.homePage}
            element={<Home socket={socket} />}
          ></Route>
          <Route
            path={paths.chatRoom}
            element={<ChatPage socket={socket} />}
          ></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
